@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body, html {
  font-family: 'K2D', sans-serif;
  background-color: #00092D;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  overflow-x: hidden;
}

header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  body, html {
    flex-direction: column;
  }

  header {
    flex-direction: column;
    padding: 1rem 0;
  }
}

@media (max-height: 500px) {
  body, html {
    flex-direction: column;
  }

  header {
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
  }
}
