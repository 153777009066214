.title-projects {
    color: #FED500;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 0;
  }

.projects-container-main {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 4rem;
    bottom: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.projects-container-1 {
    display: flex;
    height: 720px;
    width: 420px;
    flex-direction: column;
    border: solid 1px gray;
    border-radius: 20px;
    align-content: center;
    justify-content: normal;
    align-items: center;
    gap: 1rem;
    position: relative;
    margin: 1rem;
    padding: 1rem;
  }

.text-project-1 {
    color: white;
    font-size: 0.8rem;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 1rem;
  }

.project-img-1 {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 2rem;
    padding: 0;
    width: 400px;
    height: 250px;
    background-size: cover;
    border-radius: 20px;
    object-fit: cover;

  }

.project-img-2 {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 2rem;
    padding: 0;
    width: 300px;
    height: 300px;
    background-size: cover;
    border-radius: 20px;
  }
  

@media screen and (max-width: 768px) {
  
  .projects-container-main{
    margin: 1rem;
    padding: 1rem;
  }

  .title-projects {
    padding: 2rem;
  }

  .project-img-2 {
    margin: 1rem;
  }

}