.container {
  background-color: #00092D;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  position: relative;
  margin: 2rem;
  bottom: 2rem;
}
  

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0.5rem;
  margin-top: 2rem;
}


.nav-link {
  color: white;
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  gap: 5rem;
  list-style: none;
  width: 100%;
  height: 100%;
  flex: 20% 20% 20%;
  position: relative;
}

.navbar-title {
  display: flex;
  color: white;
  font-size: 1.5rem;
  position: 1;
  margin: 0;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.navbar-sub-title {
  color: #FED500;
  font-size: 1rem;
  margin: 0;
  letter-spacing: 5px;
  font-weight: 140px;
  position: 2;
  margin-top: 0.1rem;
  text-align: center;
  margin-bottom: 1rem;
}

