.time-container {
    background-color: #00092D;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  
  .countdown {
    color: #FED500;
    font-size: 2.3rem;
    font-family: 'Courier New', Courier, monospace;
    margin: 1rem;
    text-align: center;
  }
  
  .title-countdown {
    color: white;
    font-size: 1.3rem;
    margin: 0.2rem;
  }
  
  .text-countdown {
    color: white;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0.4rem;
    font-style: bold;
    text-align: center;
    margin: 5px;
    margin-bottom: 2rem;
  }