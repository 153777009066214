.project-container {
  display: flex;
  flex: 0 0 60%;
  margin-left: 1rem;
  background-color: #00092D;
  width: 80%;
  height: 80%; 
  flex-direction: row; 
  justify-content: flex-start; 
  max-width: 100%;
  max-height: 100%;
  border-style: solid;
  border-color: #FED500;
  border-radius: 30px;
  align-items: center;
  margin-top: 5rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(254, 213, 0, 0.3));
}

.text-search {
  color: white;
  display: flex;
  margin: 0;
}

.text-search-div {
  margin-bottom: 2rem;
}

svg {
  height: 250px;
  width: 150px;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  margin-left: 3rem;
  margin-right: 1rem;
  animation: float 10s infinite;
}

.project-container {
  display: flex;
  flex: 0 0 60%;
  margin-left: 1rem;
  background-color: #00092D;
  width: 80%;
  height: 80%; 
  flex-direction: row; 
  justify-content: flex-start; 
  max-width: 100%;
  max-height: 100%;
  border-style: solid;
  border-color: #FED500;
  border-radius: 30px;
  align-items: center;
  margin-top: 5rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(254, 213, 0, 0.3));
}

.text-search {
  color: white;
  display: flex;
  margin: 0;
}

.text-search-div {
  margin-bottom: 2rem;
}

svg {
  height: 250px;
  width: 150px;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  margin-left: 3rem;
  margin-right: 1rem;
  animation: svgfloat 4s infinite;
}

@keyframes svgfloat {
  0%, 100% {
    transform: translateY(10);
  }
  80% {
    transform: translateY(-8px);
  }
}

.separator-row-3 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: flex-start;
  margin: 5rem;
  padding: 1rem;
  margin: 1rem;
  align-items: center;
  margin-bottom: 10rem;
}

@media (max-width: 1380px) {
  .separator-row-3 {
    flex-direction: column;
    align-items: center;
  }
}

.title-project-search {
  color: #FED500;;
  font-size: 1.15rem;
  display: flex;
}

.project-container-text {
  color: white;
  font-size: 0.7rem;
  margin: 0rem;
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
  align-self: center;
  height: 100%;
  width: 100%;
  margin-right: 1rem;
}

.presentation-container-list {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100%; 
  list-style: none; 
  padding: 0; 
}

.bi-exclamation-triangle {
  color: red;
  margin-right: 0.5rem;
}

.li-presentation {
  font-size: 1rem;
  margin: 1rem;
}

@media screen and (max-width: 768px) {
  .project-container {
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }

  .projects-container-1 {
    width: 100%;
    margin: 1rem 0;
  }

  .project-img-1 {
    width: 100%;
    height: 200px;
  }

  .project-img-2 {
    width: 100%;
    height: 250px;
  }

  svg {
    height: 150px;
    width: 100px;
    margin: 0;
    padding: 0;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
  }

  .project-container-text {
    margin: O;
    margin-top: 1rem;
    text-align: center;
    padding: 0;
  }

  .separator-row-3 {
    margin: 1rem;
    margin-bottom: 1rem;
  }
}
