.ligne {
  width: 100%;
  height: 2px;
  background-color: silver;
  position: relative;
  margin-bottom: 4rem;
  box-shadow: 0 0 10px silver;
  background-color:  #00072700;
}

.fusee {
  width: 80px;
  height: 80px;
  position: absolute;
  rotate: 44deg;
  bottom: -40px;
  left: 0;
  animation: deplacerFusée 25s linear infinite;
}

@keyframes deplacerFusée {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
